import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EventInput } from '@fullcalendar/core';
import { addHourDateCustom } from '@shared/utils/utils';

import { lastValueFrom } from 'rxjs';
import { ResponseBackAzure } from '@core/models/responsehttp.model';
import { TypesofAppointmentsObject } from '@core/models/calendario.model';

@Injectable({
  providedIn: 'root',
})
export class CalendarioService {
  INITIAL_EVENTS: EventInput[] = [];
  TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
  TYPES_CITAS: TypesofAppointmentsObject[] = [];
  constructor(protected _http: HttpClient) {}

  async stateEventsToPromise(body) {
    return await lastValueFrom(this.getCitasV2(body));
  }

  getCitasToEvents(array): EventInput[] {
    this.INITIAL_EVENTS = [];
    array.forEach((item, index) => {
      let typeEvent =
        this.TYPES_CITAS.length > 0
          ? this.TYPES_CITAS.find((i) => i.value == item.cita.tipo_cita || i.name == item.cita.tipo_cita)
          : null;

      item.cita.hora = item.cita.hora && item.cita.hora != '' ? item.cita.hora.padStart(8, '0') : '12:00:00';

      const event = {
        backgroundColor: typeEvent?.color ?? '#86efac',
        display: typeEvent?.color ?? '#86efac',
        textColor: '#171717',
        description: !item.cita.title || item.cita.title.trim() === '' ? item.cita.tipo_cita : item.cita.title,
        id: index,
        start:
          !item.cita.start || item.cita.start.trim() === '' ? `${item.cita.dia}T${item.cita.hora}` : item.cita.start,
        end:
          !item.cita.end || item.cita.end.trim() === ''
            ? addHourDateCustom(`${item.cita.dia}T${item.cita.hora}`, 1)
            : item.cita.end,
        title: typeEvent?.nombre_corto ?? 'nombre_corto',
        allDay: item.cita.allDay ?? false,
        detailEvent: item ?? [],
      };
      this.INITIAL_EVENTS.push(event);
    });
    return this.INITIAL_EVENTS;
  }

  getDataSourceCitas(list) {
    return list.map((item) => {
      return {
        descriptionAuto: `${item.vehiculo.marca ?? ''} ${item.vehiculo.modelo ?? ''} ${item.vehiculo.agno ?? ''}`,
        ...item.cita,
        comprador: item.comprador,
        registro: item.registro,
        vendedor: item.vendedor,
        vehiculo: item.vehiculo,
        autoId: item.vehiculo.idAutomovil,
        responsable: item.registro.carantyhost,
        created_by: item.registro.created_by,
        cita: item.cita,
        comentarios: item.comentarios,
      };
    });
  }

  setTypesCitas(types) {
    this.TYPES_CITAS = types;
  }

  getCitasV2(body) {
    return this._http.post<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/citas-format-range?backend=azure`,
      body
    );
  }

  postCitaRealizada(body) {
    return this._http.post(`${environment.WebApiUrlCarantyamdev}/cita_unique/realizada?backend=azure`, body);
  }

  postCancelacionCita(idCita: string, idMotivo: string, descripcion: any) {
    return this._http.post<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/cita_unique/${idCita}/${idMotivo}?backend=azure`,
      descripcion
    );
  }

  getMotivosCancelacionCita() {
    return this._http.get<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/motivo_cancelacion?backend=azure`);
  }

  //Catalogo de citas
  getTypesofAppointments() {
    return this._http.get<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/citas_catalogo?backend=azure`);
  }
  postTypesofAppointments(body) {
    return this._http.post<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/citas_catalogo?backend=azure`,
      body
    );
  }
  putTypesofAppointments(body) {
    return this._http.put<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/citas_catalogo?backend=azure`, body);
  }

  postComentario(body) {
    return this._http.post(`${environment.WebApiUrlCarantyamdev}/cita_unique/comentario?backend=azure`, body);
  }

  getComentarios(idCita: string) {
    return this._http.get(`${environment.WebApiUrlCarantyamdev}/cita_unique/comentario/${idCita}?backend=azure`);
  }

  putComentario(body) {
    return this._http.put(`${environment.WebApiUrlCarantyamdev}/cita_unique/comentario?backend=azure`, body);
  }

  //Citas canceladas
  getCitasCanceladas(body) {
    return this._http.post(`${environment.WebApiUrlCarantyamdev}/citas-format-cancel?backend=azure`, body);
  }

  getShowRoomsArray() {
    return this._http.get(`${environment.WebApiUrlCarantyamdev}/showroom/getShowRoomsArray`);
  }

  getImagenCita(citaId: string) {
    return this._http.get(`${environment.WebApiUrlCarantyamdev}/citas/file/${citaId}?backend=azure`);
  }

  relationshipEventToCar(id_cita: string, id_automovil: string) {
    return this._http.post<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/citas/automovil/asignar?backend=azure`,
      {
        id_cita,
        id_automovil,
      }
    );
  }
}
